@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;

.projects {
    margin: 0 1rem 0 1rem;
    padding-bottom: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include desktop {
        @include desktop-margins;
        margin: 3rem auto;
        padding-bottom: 0;
        width: 100%;
        align-items: flex-start;
    }

    &__title {
        padding: 1.5rem 0 2rem;
        font-size: 1.75rem;
        text-transform: uppercase;
        text-align: center;
        color: $basic-white;
        
        @include desktop {
            padding: 0 0 5rem 0;
            width: 100%;
            font-size: 2.25rem;
            text-transform: uppercase;
            text-align: center;
        }
    }

    &__cards-container {
        display: flex;
        flex-direction: column;

        @include desktop {
            width: 100%;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    }

    &__card {
        margin-bottom: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $basic-white;
        opacity: 0;
        transform: translateY(-50px);
        transition: opacity 1s ease-out, transform 1s ease-out;

        @include desktop {
            margin: 0;
            border: 10px solid $basic-white;
            width: 32rem;
            height: 21rem;
            justify-content: space-between;
            box-shadow: 10px 10px 0px 3px rgba(255,173,244,0.5);
        }

        &:hover {
            transform: translateY(-10px);
        }

        &--spaced {
            @include desktop {
                margin-top: 3rem;
            }
        }
    }

    &__information {
        padding: 0.5rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @include desktop {
            padding: 1.5rem 1rem;
        }
    }

    &__icon {
    width: 100%;
    border: 2px solid $basic-black;
    object-fit: cover;

        @include desktop {
            height: 15rem;
        }
    }

    &__links-container {
        display: flex;
        justify-content: space-between;
    }

    &__subtitle {
        font-size: 1rem;

        @include desktop {
            padding: 0.5rem 0;
        }
    }

    &__github {
        width: 1.75rem;
        height: 1.75rem;

        @include desktop {
            width: 2.5rem;
            height: 2.5rem;
        }
    }

    &__deployed-link {
        margin-left: 0.5rem;
        width: 1.75rem;
        height: 1.75rem;

        @include desktop {
            margin-left: 1rem;
            width: 2.5rem;
            height: 2.5rem;
        }
    }
}

.fade-in-down {
    opacity: 1;
    transform: translateY(0);
}

@keyframes fade-in-down {
    0% {
        opacity: 0;
        transform: translateY(-50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}